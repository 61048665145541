/* Day Cell */

export default function DayCell({ day, onClick }) {
   const day_num = day.toFormat("d")
   const id = day.toFormat("yyyy-LL-dd")

   return (
      <td id={id} onClick={onClick} className="events">
         {day_num}
      </td>
   )
}
