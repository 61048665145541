import { Link } from "react-router-dom"
import { DateTime } from "luxon"

/* Display the sumup images for the date */

export default function DayPreview({ id, events }) {
   const day = DateTime.fromFormat(id, "yyyy-LL-dd")
   // is there an event on this days ?
   const day_events = events
      ? events.filter((evt) => day.hasSame(evt.date, "day"))
      : []
   // console.log(day)
   // console.log(day_events)

   return day_events.map((e, i) => {
      return (
         <div key={i} className="popover-content">
            <p className="popover-title">{e.dir}</p>
            <Link to={"/" + e.dir + "/" + e.obsid} title={e.dir}>
               <img width="300" src={e.sumup} alt={"Sumup for " + e.dir} />
            </Link>
         </div>
      )
   })
}
