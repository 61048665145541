export default function SumupVideo(props) {
   const movie_mp4 = `/${props.date}/IMAGES/${props.file}`
   const movie_webm =
      `/${props.date}/IMAGES/` + props.file.split(".")[0] + ".webm"

   return (
      <video controls width="700">
         <source src={process.env.PUBLIC_URL + movie_webm} type="video/webm" />
         <source src={process.env.PUBLIC_URL + movie_mp4} type="video/mp4" />
      </video>
   )
}
