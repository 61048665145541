import React, { Fragment, useState } from "react"
import Popover from "@mui/material/Popover"
import Button from "@mui/material/Button"

// import "./TestCal.css";

function Table({ onClick, onMouseEnter, onMouseLeave }) {
   return (
      <table>
         <tbody>
            <tr>
               <td
                  //   onMouseEnter={handlePopoverOpen}
                  //   onMouseLeave={handlePopoverClose}
                  onClick={onClick}
               >
                  test click
               </td>
               <td
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  // onClick={handleClick}
               >
                  test hover
               </td>
            </tr>
         </tbody>
      </table>
   )
}

function TestCal() {
   const [anchorEl, setAnchorEl] = useState(null)
   const open = Boolean(anchorEl)

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
      console.log(event.currentTarget)
   }

   const handlePopoverOpen = (event) => {
      console.log("handlePopoverOpen")
      if (anchorEl === null || event.currentTarget !== anchorEl) {
         setAnchorEl(event.currentTarget)
         console.log(event.currentTarget)
      }
   }

   const handlePopoverClose = () => {
      console.log("handlePopoverClose")
      setAnchorEl(null)
   }

   return (
      <Fragment>
         <Popover
            id="popover"
            sx={{
               pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            // onClose={handleClose}
            onClose={handlePopoverClose}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "center",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "center",
            }}
         >
            text
         </Popover>
         <Button
            variant="contained"
            // onMouseEnter={handlePopoverOpen}
            // onMouseLeave={handlePopoverClose}
            onClick={handleClick}
         >
            Open Popover
         </Button>
         <center>
            <Table
               onClick={handleClick}
               onMouseEnter={handlePopoverOpen}
               onMouseLeave={handlePopoverClose}
            />
         </center>
      </Fragment>
   )
}

export default TestCal
