/* REACT */
import { DateTime } from "luxon"

/* LOCAL */
import { SkipPrevMonth, SkipAfterMonth } from "./utils"
import DaysOfMonth from "./DaysOfMonth"

/* A row for a month */
export default function RowMonth({ selectedYear, events, onClick }) {
   // Label for the months
   const months = [...Array(12)].map((i, m) =>
      DateTime.fromFormat(`2000 ${m + 1} 01`, "yyyy L dd").toFormat("MMM")
   )

   return months.map((m, i) => {
      const first_day = DateTime.fromFormat(
         `${selectedYear} ${i + 1} 01`,
         "yyyy L dd"
      )

      const first_day_dow = parseInt(first_day.toFormat("c")) - 1
      const last_day = first_day.plus({ months: 1 }).minus({ days: 1 })
      const nb_days = last_day.diff(first_day, "days").days + 1
      const after_days = 37 - nb_days - first_day_dow

      return (
         <tr key={i}>
            <td className="month-name">{m}</td>
            <SkipPrevMonth first_dayOfWeek={first_day_dow} />
            <DaysOfMonth
               onClick={onClick}
               selectedYear={selectedYear}
               nb_days={nb_days}
               month={i}
               events={events}
            />

            <SkipAfterMonth after_days={after_days} last_day={last_day} />
         </tr>
      )
   })
}
