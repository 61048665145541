/* REACT */
import { DateTime } from "luxon"
import { Fragment } from "react"

/* LOCAL */
import { WeekSep } from "./utils"
import DayCell from "./DayCell"

/* Days of month */
export default function DaysOfMonth({
   selectedYear,
   nb_days,
   month,
   onClick,
   events,
}) {
   return [...Array(nb_days)].map((dd, ii) => {
      const day = DateTime.fromFormat(
         `${selectedYear} ${month + 1} ${ii + 1}`,
         "yyyy L d"
      )
      const wday = day.toFormat("c")

      // is there an event on this days ?
      const day_events = events
         ? events.filter((evt) => day.hasSame(evt.date, "day"))
         : []

      return (
         <Fragment key={ii}>
            {day_events.length > 0 ? (
               <DayCell day={day} onClick={onClick} />
            ) : (
               <td>{ii + 1}</td>
            )}
            <WeekSep weekDay={wday} component="td" />
         </Fragment>
      )
   })
}
