import { useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"

/* MUI */
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import Grid from "@mui/material/Unstable_Grid2"
import Input from "@mui/material/Input"

import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"

/* HIGHCHARTS */

import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

/* LOCAL */
import SumupVideo from "../Components/SumupVideo.js"
import myData from "../data/files.json"

function numToImg(date, num) {
   let value = num === "" ? 0 : Number(num)
   const rep = `/${date}/PNG/`
   const fdate =
      date.substring(0, 4) + date.substring(5, 7) + date.substring(8, 10)
   const file =
      rep +
      fdate +
      "_Nen_S" +
      value.toString().padStart(3, "0") +
      "_0_4Stokes.png"
   return file
}

export default function Sumup() {
   let { date } = useParams()

   // YEAR
   const year = date.substring(0, 4)

   // IMAGES PER MINUTE

   const images = new Set(myData[date].files)
   const nb_images = images.size

   // SLIDER

   const sliderRef = useRef(null)
   const [value, setValue] = useState(0)
   const [filename, setFilename] = useState(numToImg(date, 0))

   const handleSliderChange = (event, newValue) => {
      setAllValues(newValue)
   }

   const handleInputChange = (event) => {
      let newValue = event.target.value === "" ? 0 : Number(event.target.value)
      setAllValues(newValue)
   }

   const handleBlur = () => {
      if (value < 0) {
         setValue(0)
      } else if (value > nb_images) {
         setValue(nb_images)
      }
   }

   function valuetext(value) {
      return `${value}`
   }

   // CHART

   function initOptions(x) {
      return {
         title: {
            text: myData[date].sumup,
         },
         legend: {
            enabled: false,
         },
         xAxis: {
            visible: true,
            crosshair: true,
            plotLines: [
               {
                  color: "#EE7A30",
                  width: 2,
                  value: x,
               },
            ],
         },
         yAxis: {
            visible: false,
         },
         tooltip: {
            format: '<span style="font-size: 1.5em">{x}</span>',
            shared: true,
         },
         accessibility: {
            enabled: false,
         },
         width: 750,
         chart: {
            plotBackgroundImage: `${process.env.PUBLIC_URL}/${date}/PNG/${myData[date].sumup}`,
            events: {
               click: function (e) {
                  var value = Math.round(Number(e.xAxis[0].value))
                  setAllValues(value)
               },
            },
         },
         series: [
            {
               data: [...Array(nb_images).keys()],
               color: "#00000000", // transparent
               point: {
                  events: {
                     click: function (e) {
                        setAllValues(e.point.x)
                     },
                  },
               },
            },
         ],
      }
   }

   const [options, setOptions] = useState(initOptions(0))

   // COMMON ACTIONS

   function setAllValues(value) {
      setValue(value)
      setFilename(numToImg(date, value))
      setOptions(initOptions(value))
      sliderRef.current.focus()
   }

   // TABS

   const [tab, setTab] = useState("1")

   const handleTabChange = (event, newValue) => {
      setTab(newValue)
   }

   return (
      <Grid container spacing={2}>
         <Grid xs={6}>
            <HighchartsReact highcharts={Highcharts} options={options} />

            <Box sx={{ width: 700, ml: "50px" }}>
               <Slider
                  value={value}
                  min={0}
                  max={nb_images}
                  getAriaValueText={valuetext}
                  valueLabelDisplay="off"
                  onChange={handleSliderChange}
                  aria-label="Time"
               />

               <Input
                  sx={{
                     width: 70,
                  }}
                  value={value}
                  size="small"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  ref={sliderRef}
                  autoFocus={true}
                  inputProps={{
                     step: 1,
                     min: 0,
                     max: { nb_images },
                     type: "number",
                     "aria-labelledby": "input-slider",
                  }}
               />
            </Box>
         </Grid>
         <Grid xs={6}>
            <TabContext value={tab}>
               <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                     onChange={handleTabChange}
                     aria-label="lab API tabs example"
                  >
                     <Tab label="Stokes" value="1" />
                     {myData[date].videos.map((file, idx) => {
                        const label =
                           myData[date].videos.length > 1
                              ? `Video ${idx + 1}`
                              : "Video"
                        const tab_value = (idx + 2).toString()
                        return (
                           <Tab label={label} value={tab_value} key={idx + 2} />
                        )
                     })}
                  </TabList>
               </Box>
               <TabPanel value="1" key="1">
                  <p>
                     <img
                        src={process.env.PUBLIC_URL + "/" + filename}
                        alt="Sum up"
                        title="Sum up"
                     />
                  </p>
               </TabPanel>
               {myData[date].videos.map((file, idx) => {
                  const tab_value = (idx + 2).toString()
                  return (
                     <TabPanel value={tab_value} key={idx}>
                        <SumupVideo date={date} file={file} />
                     </TabPanel>
                  )
               })}
            </TabContext>
         </Grid>
         <Grid xs={12}>
            <Link to={"/calendar/" + year}>Back to calendar</Link>
         </Grid>
      </Grid>
   )
}
