/* REACT */
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

/* MUI */
import Popover from "@mui/material/Popover"

/* LOCAL */
import "./Calendar.css"
// import myData from "../data/files.json"
import RowMonth from "../Components/Calendar/RowMonth"
import TableHeader from "../Components/Calendar/TableHeader"
import DayPreview from "../Components/DayPreview"
import { julian2epoch } from "../Components/Calendar/utils"

// CALENDAR STUFF

export default function Calendar() {
   // YEAR SELECTION
   const currentYear = new Date().getFullYear()
   let { year } = useParams()
   const [selectedYear, setSelectedYear] = useState(
      year ? parseInt(year) : currentYear
   )

   function onPrevYear() {
      setSelectedYear(selectedYear - 1)
   }

   function onNextYear() {
      setSelectedYear(selectedYear + 1)
   }

   const [popoverContent, setPopoverContent] = useState(null)

   function datePicked(e, events) {
      setAnchorEl(e.currentTarget)
      setPopoverContent(<DayPreview id={e.currentTarget.id} events={events} />)
   }

   // EVENTS

   const [epnEvents, setEpnEvents] = useState(null)

   useEffect(() => {
      const query = {
         //   cors: "https://cors-anywhere.herokuapp.com/",
         url: "https://nenusun.obspm.fr/__system__/adql/query/form?",
         params: new URLSearchParams({
            __nevow_form__: "genForm",
            query:
               "select time_min, time_max, obs_id, access_url " +
               "from nenusun.epn_core where Granule_gid='L1-preview'",
            _FORMAT: "JSON",
            submit: "Go",
            _TIMEOUT: 5,
            MAXREC: 10000,
         }),
      }

      fetch(query.url + query.params.toString(), {
         method: "GET",
      })
         .then((response) => {
            return response.json()
         })
         .then((data) => {
            setEpnEvents(data["data"])
         })
         .catch((error) => console.log(error))
   }, [])

   const events = epnEvents
      ? epnEvents.map((e) => {
           const e_date = julian2epoch(e[0]).startOf("day")
           return {
              date: e_date,
              obsid: e[2],
              dir: e_date.toFormat("yyyy-LL-dd"),
              sumup: e[3].replace("BST_Sum_Up.png", "BST.png"),
           }
        })
      : null

   const [anchorEl, setAnchorEl] = useState(null)
   const popover_open = Boolean(anchorEl)

   const handleClose = () => {
      setAnchorEl(null)
   }

   return (
      <div>
         <Popover
            id="popover"
            open={popover_open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
               vertical: "center",
               horizontal: "right",
            }}
            transformOrigin={{
               vertical: "center",
               horizontal: "left",
            }}
         >
            {popoverContent}
         </Popover>
         <div className="calendar-controls">
            <div className="control" onClick={onPrevYear}>
               «
            </div>
            <div className="current-year">{selectedYear}</div>
            <div className="control" onClick={onNextYear}>
               »
            </div>
         </div>

         <div className="calendar-content">
            <table className="calendar">
               <thead className="day-headers">
                  <tr>
                     <th></th>
                     <TableHeader />
                  </tr>
               </thead>
               <tbody>
                  <RowMonth
                     selectedYear={selectedYear}
                     onClick={(e) => datePicked(e, events)}
                     events={events}
                  />
               </tbody>
            </table>
         </div>
      </div>
   )
}
