/* REACT */
import { Fragment } from "react"
import { WeekSep } from "./utils"

/* Displays the days names
   There is always a month beginning a Monday => start on monday
   For a 31-days month starting a sunday, need to set 31 + 6 empty days
   Result = 37 cells max
 */
export default function TableHeader() {
   const weekDays = ["M", "T", "W", "T", "F", "S", "S"]
   return [...Array(37)].map((d, i) => {
      return (
         <Fragment key={i}>
            <th>{weekDays[i % 7]}</th>
            <WeekSep weekDay={(i % 7) + 1} component="th" />
         </Fragment>
      )
   })
}
