import { createBrowserRouter, RouterProvider } from "react-router-dom"

import App from "./App.js"
import Sumup from "./Pages/Sumup.js"
import Obsid from "./Pages/Obsid.js"
import Calendar from "./Pages/Calendar.js"
import TestCal from "./Pages/TestCal.js"

export default function Routes() {
   const router = createBrowserRouter(
      [
         {
            path: "/",
            element: <App />,
         },
         {
            path: "sumup/:date",
            element: <Sumup />,
         },
         {
            path: ":date/:obsid",
            element: <Obsid />,
         },
         {
            path: "calendar/",
            element: <Calendar />,
         },
         {
            path: "calendar/:year",
            element: <Calendar />,
         },
         {
            path: "test",
            element: <TestCal />,
         },
      ],
      { basename: "/catalogue" }
   )

   return <RouterProvider router={router} />
}
