import React from "react"
import ReactDOM from "react-dom/client"
import Link from "@mui/material/Link"

import "./index.css"
import "./App.css"
import Routes from "./routes"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
   <React.StrictMode>
      <div className="App">
         <header className="App-header">
            <h1>
               <img src="/catalogue/Observatoire_de_Paris-CoMarquage-BLANC.png" alt="Logo Observatoire de Paris" />
               <Link href="/catalogue">NENU-SUN</Link>
               <img src="/catalogue/SOLER_logo.png" alt="SOLER logo" />
            </h1>
         </header>
         <Routes />
      </div>
   </React.StrictMode>
)
