import { Link } from "react-router-dom"

function App() {
   return (
      <p className="home">
         <Link to="/calendar">Access to data</Link>
         <br />
         <br />
         <img src="/catalogue/nenusun.png" alt="Nenu-sun logo" width="500" />
      </p>
   )
}

export default App
